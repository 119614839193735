import React, { useMemo } from "react";
import { CellProps } from "./Cell";
import { BASE_COLUMN_TYPES, ColumnDataMap } from "../config/types";
import { formatDateFromString } from "../../../../utils";
import { Csku, Typography } from "@commonsku/styles";

type TColumn = typeof BASE_COLUMN_TYPES.Date;
const DateCell = ({
  column,
  row,
  className,
  onClick,
  style = {},
  textStyle = {},
}: CellProps<TColumn>) => {
  const { accessorKey, dataTransform } = column;

  const { date } = useMemo<ColumnDataMap<TColumn>>(() => {
    const rawData = row.original[accessorKey];

    if (dataTransform) return { ...dataTransform(rawData) };

    const key = Object.keys(rawData);
    if (key.length !== 1) {
      throw new Error(
        "DateCell can only handle one key if dataTransform is not provided",
      );
    }

    return {
      date: formatDateFromString(rawData[key[0]]),
    };
  }, [accessorKey, dataTransform, row.original]);

  return (
    <Csku className={className} onClick={onClick}>
      <div style={style} title={date}>
        <Typography.Span
          style={{
            display: "block",
            alignContent: "center",
            color: style.color,
            ...textStyle,
          }}
        >
          {date}
        </Typography.Span>
      </div>
    </Csku>
  );
};

export default DateCell;
